import React from 'react'
import MainLayout from '../layouts/main-layout'
import SEO from '../components/seo'
import CTA from '../components/cta'
import ContentImageBlock from '../components/content-image-block'
import image from '../images/colin-paul-van.png'
import image2 from '../images/colin-paul.png'
import image3 from '../images/van2.jpg'

const AboutPage = () => (
  <MainLayout titleText="About Us">
    <SEO title="About Us" />

    <ContentImageBlock
      paragraphs={[
        'We know how important choosing the right flooring is to you. As professional carpet and vinyl fitters, we have over 60 years of experience to help you make this decision. Our hard earned reputation for quality and value means we can accommodate your requirements and budget. From a small bathroom to a large classroom and more, we can cater for your domestic and commercial needs.'
      ]}
      image={image}
    />

    <ContentImageBlock
      paragraphs={[
        'Paul, being brought up within the family business, still holds the same values as his late father Colin, and continues to drive the business forward. He has built up a solid reputation, both within the town and surrounding areas of the Vale, for reliability and honesty with his customers and local businesses.',
        'Paul has reached the level of NICF Master Fitter, the highest grade a fitter can achieve.'
      ]}
      image={image2}
      imageOnRight
    />

    <ContentImageBlock
      paragraphs={[
        'We offer a mobile shop service which we bring to you on a free home visit. Our mobile shop is equipped with all leading brands in a range of different styles and colours. If nothing suits your needs, we can explore other options with you.',
        'With plenty of samples to look through, we will help you choose your perfect new floor, and put together a free quotation for you. You can also take inspiration from our gallery which showcases a selection of our previous projects.'
      ]}
      image={image3}
    />

    <CTA
      titleText="You're in good company"
      buttonTo="/contact"
      buttonText="Contact Us"
    />
  </MainLayout>
)

export default AboutPage
