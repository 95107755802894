import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Container = styled.div`
  padding: 0 1rem;
  margin: 1rem 0;

  ${breakpoint('tablet')`
    padding: 0 2rem;
    margin: 3rem 0;
  `}

  ${breakpoint('desktop')`
    padding: 0 6rem;
    margin: 6rem 0;
  `}

  display: flex;
  flex-direction: column;
  background-color: white;

  ${props => props.grey && css`
    background-color: #EFEFEF;
  `}
`

export const Content = styled.div`
  margin: 0;
  padding: 0;
  flex-direction: column;

  ${breakpoint('desktop')`
    padding: 0 5rem;
    flex-direction: row;
  `}

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ImgContainer = styled.div`
  ${breakpoint('desktop')`
    flex: 1 0 40%;
    margin-right: 2rem;

    ${props => props.imageOnRight && css`
      order: 2;
      margin-right: 0;
      margin-left: 2rem;
    `}
  `}
`

export const Img = styled.img`
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const P = styled.p`
  color: #1F1F1F;
`
