import React from 'react'
import Button from '../button'
import { Container, Title, Text } from './styles'

const CTA = ({ titleText, text, buttonHref, buttonTarget, buttonText, buttonTo }) => (
  <Container>
    <Title>{titleText}</Title>
    {text && <Text>{text}</Text>}
    <Button to={buttonTo} href={buttonHref} target={buttonTarget} text={buttonText} success large />
  </Container>
)

CTA.defaultProps = {
  buttonTarget: "_self"
}

export default CTA
