import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Container = styled.div`
  background-color: #BF2D38;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 4rem 1rem;

  ${breakpoint('tablet')`
    padding: 4rem;
  `}

  ${breakpoint('desktop')`
    padding: 6rem;
  `}
`

export const Title = styled.span`
  color: white;
  display: block;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
  font-family: "Merit";
  text-transform: uppercase;
  font-size: 2rem;

  ${breakpoint('tablet')`
    letter-spacing: 1px;
    font-size: 2.5rem;
  `}

  ${breakpoint('desktop')`
    letter-spacing: 2px;
    font-size: 3rem;
    margin-bottom: 3rem;
  `}
`

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  margin-bottom: 3rem;
  margin-top: -2rem;
`
