import React from 'react'
import { Container, P, Content, ImgContainer, Img, TextContainer } from './styles'

const ContentImageBlock = (props) => (
  <Container {...props}>
    <Content>
      <ImgContainer imageOnRight={props.imageOnRight}>
        <Img src={props.image} />
      </ImgContainer>
      <TextContainer>
        {props.paragraphs.map((text, index) => <P key={index}>{text}</P>)}
      </TextContainer>
    </Content>
  </Container>
)

export default ContentImageBlock
